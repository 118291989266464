import React, { useEffect, useRef, useState } from 'react'
import { Link, useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom'
import QuizApi from '../services/QuizApi';
import "../assets/styles/quizResultPage.css";
import { MainNavbar } from './Common/Navbar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSort, faSortDown, faSortUp, faSpinner, faXmark } from '@fortawesome/free-solid-svg-icons';
import {
    FaAnglesRight,
    FaAnglesLeft,
    FaMagnifyingGlass,
} from "react-icons/fa6";
import { differenceInSeconds, format } from 'date-fns';
import { CustomPagination } from './ManageQuiz';
import DropDown from './Common/DropDown';

export default function QuizResult() {
    const QuestionStatusTooltip = { 'Security Stopped': ['Indicates that the quiz submission was triggered by a Security breach.', '#904800'], 'Timed-Out': ['The user did not finish the quiz within the allocated time, leading to an automatic', '#808080'], 'Completed': ['The user has answered all questions and submitted the quiz, marking it as fully completed.', '#008000'], 'In Progress': ['The quiz is currently being taken by the user and is not yet submitted.', '#4C33FF'], 'Incomplete': ['When the user has left the quiz before submitting all answers or before reaching the end of the quiz.', '#FF0000'] }
    const [searchParams, setSearchParams] = useSearchParams();
    const { quizId } = useParams();
    const navigate = useNavigate();
    const [navigationChange, setNavigationChange] = useState(false);
    const [totalReportDeleteText, setTotalReportDeleteText] = useState([false, "1 quiz report was deleted"]);

    const [page, setPage] = useState(searchParams.get('page') || 0);
    const [searchTerm, setSearchTerm] = useState(searchParams.get('se') || '');
    const [finalSearchTerm, setFinalSearchTerm] = useState(searchParams.get('se') || '');
    const [sortBy, setSortBy] = useState(searchParams.get('so') || '-quizstarttime');
    const [pageRecordLimit, setPageRecordLimit] = useState(searchParams.get('limit') || parseInt(localStorage.getItem('pageRecordLimit')) || 20);
    const [filter, setFilter] = useState({
        start_time: searchParams.get('st') || '',
        end_time: searchParams.get('et') || '',
        filterValueKey: searchParams.get('fvk') || 'ALL'
    });

    const [quizResultData, setQuizResultData] = useState([]);
    const [isDeleteDropdownVisible, setIsDeleteDropdownVisible] = useState(false);
    const [selectedCheckboxes, setSelectedCheckboxes] = useState(JSON.parse(searchParams.get('sch')) || []);
    const quizTitle = useRef('Quiz Name');
    const totalRecords = useRef(0);
    const totalPages = useRef(0);
    const location = useLocation();
    const [isLoading, setIsLoading] = useState(true);
    const accountCreatedDate = useRef('');


    const handleSearch = (e) => {
        setFinalSearchTerm(searchTerm);
    }

    const handleSort = (e) => {
        selectedCheckboxes.length > 0 && setSelectedCheckboxes([]);
        if (sortBy === e) {
            if (sortBy.startsWith('-')) {
                setSortBy(e);
            } else {
                setSortBy(`-${e}`);
            }
        } else {
            setSortBy(e);
        }
    }

    const setDateFilterChange = (option) => {
        if(option.value === 'ALL'){
            setFilter((prevFilter) => ({ ...prevFilter, start_time: '', end_time: '', filterValueKey: option.value}));
        }else{
            setFilter((prevFilter) => ({ ...prevFilter, start_time: option.startTime, end_time: option.endTime, filterValueKey: option.value}));
        }
    }

    const handlePageChange = (e) => {
        setSelectedCheckboxes([]);
        setPage(e);
        window.scrollTo(0, 0);
    }

    const handlePageRecordLimitChange = (e) => {
        setPageRecordLimit(e.target.value);
        localStorage.setItem('pageRecordLimit', e.target.value);
        window.scrollTo(0, 0);
    }

    const handleCheckBoxChange = (e) => {
        const { value, checked } = e.target;
        if (value === 'selectAll') {
            if (checked) {
                setSelectedCheckboxes(quizResultData.map((report) => report.id));
            } else {
                setSelectedCheckboxes([]);
            }
        } else {
            if (checked) {
                setSelectedCheckboxes([...selectedCheckboxes, parseInt(value)]);
            } else {
                setSelectedCheckboxes(selectedCheckboxes.filter((id) => id !== parseInt(value)));
            }
        }
    }

    const confirmDelete = async () => {
        setIsDeleteDropdownVisible(false);
        try {
            setIsLoading(true);
            await QuizApi.delete(`/quiz-result/${selectedCheckboxes[0]}/`, { data: { reportIds: selectedCheckboxes } });
            if (selectedCheckboxes.length === 1) {
                setTotalReportDeleteText([true, "1 report has been deleted"]);
            } else {
                setTotalReportDeleteText([true, `${selectedCheckboxes.length} reports have been deleted`]);
            }
            setTimeout(() => {
                setTotalReportDeleteText([false, ""]);
            }, 3000);
            setSelectedCheckboxes([]);
            setQuizResultData((prev_data) => prev_data.filter((report) => !selectedCheckboxes.includes(report.id)));
            if (quizResultData.length === selectedCheckboxes.length) {
                if (page > 0) {
                    setPage((Prev_page) => Prev_page - 1);
                } else {
                    setPage(0);
                }
            }
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            console.log(error);
        }
    }

    const handleClearSearch = () => {
        setSearchTerm('');
        setFinalSearchTerm('');
    }



    useEffect(() => {

        let url = `/quiz-result/${quizId}/get_quiz_results_by_quiz_id?order=${sortBy}`;
        if (searchTerm) {
            url += `&search=${searchTerm}`;
        }
        if (filter.start_time) {
            url += `&start_time=${filter.start_time}`;
        }
        if (filter.end_time) {
            url += `&end_time=${filter.end_time}`;
        }
        url += `&page=${parseInt(page) + 1}`;
        url += `&limit=${pageRecordLimit}`;

        setIsLoading(true);
        QuizApi.get(url).then((response) => {
            setQuizResultData(response?.data?.data);
            totalRecords.current = response?.data?.total_records;
            totalPages.current = response?.data?.total_pages;
            quizTitle.current = response?.data?.quiz_title;
            accountCreatedDate.current = response?.data?.account_created_date;
            if (parseInt(page) > response?.data?.total_pages - 1) {
                if (response?.data?.total_pages === 0) {
                    setPage(0);
                } else {
                    setPage(response?.data?.total_pages - 1);
                }
            }
        }).catch((error) => {
            console.log(error);
        }).finally(() => {
            setIsLoading(false);
        });
    }, [navigationChange]);

    useEffect(() => {
        let url = `/quiz-result/${quizId}/?so=${sortBy}`;
        if (searchTerm) {
            url += `&se=${searchTerm}`;
        }
        if (filter.start_time) {
            url += `&st=${filter.start_time}`;
        }
        if (filter.end_time) {
            url += `&et=${filter.end_time}`;
        }
        if (filter.filterValueKey) {
            url += `&fvk=${filter.filterValueKey}`;
        }
        url += `&limit=${pageRecordLimit}`;
        url += `&page=${parseInt(page)}`;

        navigate(url, { replace: true, preventScrollReset: false });
        setNavigationChange(!navigationChange);
    }, [page, finalSearchTerm, sortBy, filter, pageRecordLimit, quizId]);



    const deleteConfirmationModal = (
        <div className="delete-confirmation-modal">
            <div className="delete-confirmation-content">
                <p
                    style={{
                        textAlign: "left",
                        marginBottom: "5px",
                        fontSize: "20px",
                        fontWeight: "600",
                    }}
                >
                    Are you sure you want to delete these reports?
                </p>
                <p style={{ textAlign: "left" }}>
                    All reports will be removed permanently and will not be recoverable.
                    You may download all reports now before deleting.
                </p>
                <div className="deletepopup_btn">
                    <button onClick={confirmDelete}>Delete</button>
                    <button onClick={() => setIsDeleteDropdownVisible(false)}>Cancel</button>
                </div>
            </div>
        </div>
    );

    const calculateTimeDifference = (startTime, endTime) => {
        const startTimeObj = new Date(startTime);
        const endTimeObj = new Date(endTime);

        const timeDifference = differenceInSeconds(endTimeObj, startTimeObj);
        const hours = Math.floor(timeDifference / 3600);
        const minutes = Math.floor((timeDifference % 3600) / 60);
        const seconds = timeDifference % 60;

        return `${hours}h ${minutes}m ${seconds}s`;
    }

    const renderSortingIcon = (columnKey) => {
        let sortByOrder = sortBy;
        if (sortBy.startsWith('-')) {
            sortByOrder = sortBy.slice(1);
        }

        if (sortByOrder === columnKey) {
            return sortBy.startsWith('-') ? (
                <FontAwesomeIcon icon={faSortDown} />
            ) : (
                <FontAwesomeIcon icon={faSortUp} />
            );
        }
        return <FontAwesomeIcon icon={faSort} />;
    };


    return (
        <>
            <MainNavbar />
            <div className="container_main restorescroll reportsPageContainer">
                {
                    totalReportDeleteText[0] && (
                        <div className="report-delete">{totalReportDeleteText[1]} <FontAwesomeIcon onClick={() => { setTotalReportDeleteText([false, ""]) }} className="close-report-delete" icon={faXmark} /> </div>
                    )
                }
                <div className="quiz-title-head">
                    {quizTitle.current}
                </div>
                <div className="formclass">
                    <div className="searchcontainer">
                        <div style={{ display: "flex", alignItems: "flex-end", }}>
                            {
                                parseInt(window.history.state.idx) !== 0 && (
                                    <button className="btnquiz btnResultQuiz" style={{ fontSize: 18, minWidth: "130px", paddingBlock: "0.35rem" }} onClick={() => { (window.history.back()) }}>
                                        Back
                                    </button>
                                )
                            }
                            <button
                                type="button"
                                className={`btnquiz btnResultQuiz ${selectedCheckboxes.length === 0 && "disable"} ${isDeleteDropdownVisible ? "active" : ""
                                    }`}
                                style={{ fontSize: 18, minWidth: "130px", paddingBlock: "0.35rem" }}
                                onClick={() => {
                                    if (quizResultData.length === selectedCheckboxes.length) {
                                        setIsDeleteDropdownVisible(!isDeleteDropdownVisible);
                                    } else {
                                        if (selectedCheckboxes.length > 0) {
                                            confirmDelete();
                                        }
                                    }
                                }}
                            >
                                Delete
                            </button>
                            <button
                                className={`btnquiz btnResultQuiz ${selectedCheckboxes.length === 0 && "disable"}`}
                                style={{ fontSize: 18, minWidth: "130px", paddingBlock: "0.35rem" }}
                            >
                                Download
                            </button>


                        </div>
                        <div className="main_heading " style={{ marginTop: "0px" }}>
                            Quiz Results
                        </div>
                        <div className="search_bar">
                            <button
                                title="Search"
                                type="button"
                                className="button_search"
                                onClick={() => { handleSearch(); sessionStorage.setItem("is_search", true); }}
                            >
                                <FaMagnifyingGlass />
                            </button>
                            <input
                                type="text"
                                placeholder="Search by name, email, phone, or keywords in essay answers."
                                value={searchTerm}
                                onChange={(e) => { setSearchTerm(e.target.value); e.target.value.trim() === "" && setFinalSearchTerm(""); }}
                                onKeyDown={(e) => { (e.key === "Enter") && handleSearch(); (e.key === "Enter") && sessionStorage.setItem("is_search", true); }}
                            />
                            {searchTerm && (
                                <button
                                    title="Clear Search"
                                    type="button"
                                    className="button_clear"
                                    onClick={handleClearSearch}
                                >
                                    <FontAwesomeIcon icon={faXmark} size="xl" />
                                </button>
                            )}
                        </div>
                    </div>
                    {isDeleteDropdownVisible && deleteConfirmationModal}

                    <div className="quizreporttablecontainer">
                        <table className="quizreporttable quizresulttable">
                            <tbody>
                                <tr className="header_Container">
                                    <th className="sort-by reducedwidthDateAndTime checkbox_container">
                                        <input
                                            style={{ height: 22 }}
                                            type="checkbox"
                                            value="selectAll"
                                            checked={quizResultData.length === selectedCheckboxes.length && quizResultData.length !== 0}
                                            onChange={handleCheckBoxChange}
                                        />
                                        <span onClick={() => handleSort("quizstarttime")}>
                                            Date and Time {renderSortingIcon("quizstarttime")}
                                        </span>
                                        
                                        <DropDown setDateFilterChange={setDateFilterChange} accountCreatedDate={accountCreatedDate} />
                                    </th>
                                    <th className="sort-by" onClick={() => handleSort("name")}>
                                        <span>Name {renderSortingIcon("name")}</span>
                                    </th>
                                    <th className="sort-by" onClick={() => handleSort("email")}>
                                        <span>Email {renderSortingIcon("email")}</span>
                                    </th>
                                    <th
                                        className="sort-by reducedwidthPhoneNumber"
                                        onClick={() => handleSort("phonenumber")}
                                    >
                                        <span>Phone Number {renderSortingIcon("phonenumber")}</span>
                                    </th>
                                    <th
                                        className="sort-by reducedWidthTh"
                                        onClick={() => handleSort("percentage")}
                                    >
                                        <span>Score {renderSortingIcon("percentage")}</span>
                                    </th>
                                    <th
                                        className="sort-by reducedWidthTimeTakenTableHeader"
                                        onClick={() => handleSort("quizendtime")}
                                    >
                                        <span>Time Taken {renderSortingIcon("quizendtime")}</span>
                                    </th>
                                    <th
                                        className="sort-by reducedWidthTh"
                                        onClick={() => handleSort("securityalerts")}
                                    >
                                        <span>Alerts {renderSortingIcon("securityalerts")}</span>
                                    </th>
                                    <th
                                        className="sort-by reducedWidthTh"
                                        onClick={() => handleSort("status")}
                                    >
                                        <span>Status {renderSortingIcon("status")}</span>
                                    </th>
                                    <th
                                        className="sort-by reducedWidthTh"
                                        onClick={() => handleSort("result")}
                                    >
                                        <span>Result {renderSortingIcon("result")}</span>
                                    </th>
                                    <th className="sort-by reducedWidthTh">
                                        <span>Report </span>
                                    </th>
                                </tr>
                                {
                                    isLoading ?
                                        <tr className={`${isLoading && "manage-loading"} loading-tr result-loading`} >
                                            <td colSpan={10}>
                                                <FontAwesomeIcon icon={faSpinner} spin size="2xl" className="loading-icon" />
                                            </td>
                                        </tr> :
                                        quizResultData.map((ReportData, i) => (
                                            <tr key={i}>
                                                <td className="checkbox_container">
                                                    <input
                                                        type="checkbox"
                                                        id={ReportData.id}
                                                        className="quizResultCheckboxes"
                                                        name="reportid"
                                                        checked={selectedCheckboxes.includes(ReportData.id)}
                                                        value={ReportData.id}
                                                        onChange={(event) =>
                                                            handleCheckBoxChange(event)
                                                        }
                                                    />{" "}
                                                    <span style={{ verticalAlign: "-2px" }}>
                                                        {format(
                                                            new Date(ReportData.quizstarttime),
                                                            "dd MMM yyyy hh:mm aa"
                                                        )}{" "}
                                                    </span>
                                                </td>
                                                <td>{ReportData.name || "-"}</td>
                                                <td>{ReportData.email || "-"}</td>
                                                <td>{ReportData.phonenumber || "-"}</td>
                                                <td>{ReportData.percentage}%</td>
                                                <td>
                                                    {calculateTimeDifference(
                                                        ReportData.quizstarttime,
                                                        ReportData.quizendtime
                                                    )}
                                                </td>
                                                <td style={{ textAlign: "center" }}>
                                                    {ReportData.securityalerts}
                                                </td>
                                                <td title={QuestionStatusTooltip[ReportData?.status][0]}>

                                                    <font color={`${QuestionStatusTooltip[ReportData?.status][1]}`}>
                                                        {ReportData?.status}
                                                    </font>
                                                </td>
                                                <td>
                                                    {ReportData.result ? (
                                                        <font color={`${ReportData?.result === "pass" ? "green" : "red"}`}>{ReportData?.result?.charAt(0).toUpperCase() + ReportData?.result?.substr(1)}</font>
                                                    ) : (
                                                        <font color="red">-</font>
                                                    )}
                                                </td>
                                                <td>
                                                    <Link
                                                        to={`/quiz-report/${quizId}/${ReportData.id}/?sort=${sortBy}`}
                                                        onClick={() => { sessionStorage.setItem("quizId", quizId); sessionStorage.setItem("qu", `${location.search}&sch=${JSON.stringify(selectedCheckboxes)}`) }}
                                                    >
                                                        View
                                                    </Link>
                                                </td>
                                            </tr>
                                        ))}
                            </tbody>
                        </table>
                    </div>
                </div>
                {searchTerm && quizResultData.length === 0 ? (
                    <div className="no-results-message">
                        No results found for {finalSearchTerm}.
                        <button className="btn btn-danger" onClick={handleClearSearch}>
                            Clear Search
                        </button>
                    </div>
                ) : !searchTerm && quizResultData.length === 0 && (
                    <div className="no-results-message">No reports Found.</div>
                )}

                <div className="pagination" style={{ marginTop: "30px" }}>
                    <div style={{ width: "100%", textAlign: "left" }}>
                        <div>
                            <label
                                htmlFor="integerSelect"
                                style={{ fontWeight: "600", color: "#052949", fontSize: "19px" }}
                            >
                                View results per page: &nbsp; &nbsp;
                            </label>
                            <select
                                id="integerSelect"
                                value={pageRecordLimit}
                                onChange={handlePageRecordLimitChange}
                            >
                                <option value="20">20</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                            </select>
                        </div>
                    </div>
                    {totalPages.current > 1 && (
                        <>
                            <button
                                className="previouspage pagelink"
                                onClick={() => handlePageChange(0)}
                            >
                                <FaAnglesLeft style={{ fontSize: "25px" }} />
                            </button>
                            <CustomPagination
                                currentPage={parseInt(page)}
                                pageCount={totalPages.current}
                                onPageChange={handlePageChange}
                            />
                            <button
                                className="nextpage pagelink"
                                onClick={() => {
                                    handlePageChange(totalPages.current - 1)
                                }
                                }
                            >
                                <FaAnglesRight style={{ fontSize: "25px" }} />
                            </button>
                        </>
                    )}
                </div>
            </div>

        </>
    )
}
