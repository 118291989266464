
import QuizApi from '../../services/QuizApi';
import '../../assets/styles/Settings.css'; 
import { useParams } from 'react-router-dom';
import { MainNavbar } from '../Common/Navbar';
import React, { useEffect, useState } from 'react';
import Sidebar from '../Common/Sidebar';

function BrowserSettings() {
    const { quizId } = useParams();
    const [settings, setSettings] = useState(null);
    const links = [
        { to: `/general-settings/${quizId}`, text: 'General Settings' },
        { to: `/scoring-option/${quizId}`, text: 'Scoring Options' },
        { to: `/quiz-tracking/${quizId}`, text: 'Quiz Tracking' },
        { to: `/security-settings/${quizId}`, text: 'Browser Restrictions' },
        { to: `/smart-proctoring-system/${quizId}`, text: 'Proctoring and Security' },
        { to: `/lead-integration/${quizId}`, text: 'Lead Integration' },
    ];

    const [quizTitle, setQuizTitle] = useState('');
  
    useEffect(() => {
     try {
         QuizApi.get(`/browsersettings/${quizId}/`)
           .then((response) => {
             setSettings(response.data);
             setQuizTitle(response?.data?.quiz_title);
           })
     } 
     catch (error) {
        console.error('Error getting quiz settings:', error);
      }
        
    }, [quizId]);

    const handleSelectChange = (event, key) => {
        const { value, type, checked } = event.target;
        if (type === 'checkbox') {
            setSettings({
                ...settings,
                [key]: checked ? 1 : 0,
            });
        } else {
            setSettings({
                ...settings,
                [key]: value,
            });
        }
  
        QuizApi.patch(`/browsersettings/${quizId}/`, { [key]: type === 'checkbox' ? (checked ? 1 : 0) : value })
            .then((response) => {
                console.log();
            })
            .catch((error) => {
                console.log(`Error updating ${key} in the backend: ${error}`);
            });
    };
  
    return (
      <div>
        {settings ? (
          <div>
          <MainNavbar/>
          <Sidebar quiztitle={quizTitle} links={links} />
          <div className="containermain quizsettings" >
          <br></br>
        <div className="settingsheadings">
            Browser Security
        </div>
        <br></br>
        <div className="securitysettingsoptions">
            <input type="checkbox" className="enterkeyevent" id="disableprint"
            name="disableprint"
                  onChange={(e) => handleSelectChange(e, 'disableprint')}
                  checked={settings.disableprint} ></input>
            <p>Disable Print</p>
            Disable the screen capture and print ability in the browser  <br></br> and prevent quiz takers from capturing and printing your quiz.
        </div><br></br>
              <div className="securitysettingsoptions">
                  <input type="checkbox"  className="enterkeyevent" id="disablecopy" 
                  name="disablecopy"
                  onChange={(e) => handleSelectChange(e, 'disablecopy')}
                  checked={settings.disablecopy}></input>
                  <p>Disable Copy</p>
                  Disable the copy option in browser and prevent quiz takers <br></br> from copying the questions in your quiz.
              </div><br></br>
        <div className="securitysettingsoptions">
            <input type="checkbox"  className="enterkeyevent" id="switchtabs"
            name="switchingbrowsertabs"
                  onChange={(e) => handleSelectChange(e, 'switchingbrowsertabs')}
                  checked={settings.switchingbrowsertabs} ></input>
            <p>Prevent User from Switching Browser Tabs</p>
            A warning will be displayed when Quiz takers tries to switch tabs<br></br>in a web browser.
        </div><br></br>
        <div className="securitysettingsoptions">
            <input type="checkbox" className="enterkeyevent" id="disablepasteinessay"
            name="disablepasteinessay"
                  onChange={(e) => handleSelectChange(e, 'disablepasteinessay')}
                  checked={settings.disablepasteinessay}></input>
            <p>Disable Paste in Essay Question</p>
            Disable the paste option and prevent quiz takers from pasting<br></br>answers they may have found elsewhere.
        </div><br></br>
              <div className="securitysettingsoptions">
                  <input type="checkbox"  className="enterkeyevent" id="highlightcopypaste"
                  name="highlightcopypaste"
                  onChange={(e) => handleSelectChange(e, 'highlightcopypaste')}
                  checked={settings.highlightcopypaste}></input>
                  <p>Display Copy - Paste content in yellow for Essay Questions</p>
                  Quiz taker is allowed to copy and paste the text into an essay question, <br></br> the text that is pasted will be highlighted in yellow.
                  <br></br> <em>The yellow highlights will be only visible when viewing the answer report.</em>

              </div><br></br>

        <div className="settingsheadings">
            Network Security
        </div>
        <br></br>
        <div className="securitysettingsoptions">
            <div>
               
                <input type="checkbox" name="networksecurity" id="networksecurity" 
                  onChange={(e) => handleSelectChange(e, 'networksecurity')}
                  checked={settings.networksecurity}></input>
                <p>Enable IP Base Security</p>
            Set a permissible range of IP address from which<br></br>
            the quiz can be taken.
            <br></br>
            <br></br>
            <div id="alllowedipranges">
                <span id="permissibleip">

                </span>
            </div>
                <div className="ipoption" id="ipoption" style={{display:"none"}}>
                    <div>
                        
                            
                            
                                <div className="startip">&nbsp;&nbsp;Start IP<br></br>
                                    <input type="number" className="startipclass" min="1" max="255" defaultValue="192"  name="start" id="startipfield11" required=""></input>
                                    <input type="number" className="startipclass" min="1" max="255" defaultValue="168"  name="start" id="startipfield21" required=""></input>
                                    <input type="number" className="startipclass" min="1" max="255" defaultValue="1"  name="start" id="startipfield31" required=""></input>
                                    <input type="number" className="startipclass" min="1" max="255" defaultValue="173"  name="start" id="startipfield41" required=""></input>
                                </div>
                                        
                                
                                <div className="endip">&nbsp;&nbsp;End IP<br></br>
                                    <input type="number" className="endipclass" defaultValue="192" max="255" name="end" id="endipfield11" readOnly="" disabled=""></input>
                                    <input type="number" className="endipclass" defaultValue="168" max="255" name="end" id="endipfield21" readOnly="" disabled=""></input>
                                    <input type="number" className="endipclass" defaultValue="1" max="255" name="end" id="endipfield31" required=""></input>
                                    <input type="number" className="endipclass" defaultValue="173" max="255" name="end" id="endipfield41" required=""></input>
                                </div>
                                
                                
                       
                       
                    </div>
                    <div className="addmore">
                        <a href="/security-settings/VIsNln?addIpRange=True">+ Add More</a>
                   </div>
                </div><br></br>
            </div>
            
        </div>
    </div>
</div>
        ) : (
          <p>Loading settings...</p>
        )}
      </div>
    );
  }
  
  export default BrowserSettings;